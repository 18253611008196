<template>
  <div class="row" v-if="game.League.Sport.id <= 16">
    <div class="col-2" v-for="item in arrayFT[game.League.Sport.id]" :key="item">
      <div v-if="game.Odds && game.Odds.FT && game.Odds.FT[item]">
        <div class="row mb-1 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
          <span class="text-center">{{ odds[item].name }}</span>
        </div>
        <div v-for="(main, key) in Object.values(game.Odds.FT[item].main)" :key="key">
          <div class="row my-1 oddHover forn-inline" v-for="(odd, key2) in main" :key="key2">
            <div
              class="text-center"
              @click="processId(game, 'FT', item, game.Odds.FT[item].main, odd)"
              style="cursor: pointer"
              :class="{ 'btn-odd-selected': existOdd(game, 'FT', item, game.Odds.FT[item].main, odd) }"
            >
              <template v-if="showSign(getFormat, odd[getFormat])">+</template>{{ odd[getFormat]
              }}<template v-if="odd.factor">({{ odd.factor }})</template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-3 px-0" v-if="game.Odds && game.Odds.FT && game.Odds.FT[10]">
      <div class="text-center mb-1 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
        {{ odds[10].name }}
      </div>
      <div class="text-center">
        <div class="row">
          <div class="col-4">{{ Object.keys(game.Odds.FT[10].main.main)[0] }}</div>
          <div class="col-4">{{ Object.keys(game.Odds.FT[10].main.main)[1] }}</div>
          <div class="col-4">{{ Object.keys(game.Odds.FT[10].main.main)[2] }}</div>
        </div>
        <div class="row my-1">
          <div
            class="col-4 oddHover p-0"
            style="cursor: pointer"
            :class="{
              'btn-odd-selected': existOdd(
                game,
                'FT',
                10,
                game.Odds.FT[10].main,
                Object.values(game.Odds.FT[10].main.main)[0]
              )
            }"
            @click="processId(game, 'FT', 10, game.Odds.FT[10].main, Object.values(game.Odds.FT[10].main.main)[0])"
          >
            <template v-if="showSign(getFormat, Object.values(game.Odds.FT[10].main.main)[0][getFormat])">+</template>
            {{ Object.values(game.Odds.FT[10].main.main)[0][getFormat] }}
          </div>
          <div
            class="col-4 oddHover p-0"
            style="cursor: pointer"
            :class="{
              'btn-odd-selected': existOdd(
                game,
                'FT',
                10,
                game.Odds.FT[10].main,
                Object.values(game.Odds.FT[10].main.main)[1]
              )
            }"
            @click="processId(game, 'FT', 10, game.Odds.FT[10].main, Object.values(game.Odds.FT[10].main.main)[1])"
          >
            <template v-if="showSign(getFormat, Object.values(game.Odds.FT[10].main.main)[1][getFormat])">+</template>
            {{ Object.values(game.Odds.FT[10].main.main)[1][getFormat] }}
          </div>
          <div
            class="col-4 oddHover p-0"
            style="cursor: pointer"
            :class="{
              'btn-odd-selected': existOdd(
                game,
                'FT',
                10,
                game.Odds.FT[10].main,
                Object.values(game.Odds.FT[10].main.main)[2]
              )
            }"
            @click="processId(game, 'FT', 10, game.Odds.FT[10].main, Object.values(game.Odds.FT[10].main.main)[2])"
          >
            <template v-if="showSign(getFormat, Object.values(game.Odds.FT[10].main.main)[2][getFormat])">+</template>
            {{ Object.values(game.Odds.FT[10].main.main)[2][getFormat] }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 px-0" v-if="game.Odds && game.Odds.FT && game.Odds.FT[29]">
      <div class="text-center mb-1 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
        {{ odds[29].name }}
      </div>
      <div class="text-center">
        <div class="row text-center">
          <div class="col-6">{{ Object.keys(game.Odds.FT[29].main.main)[0] }}</div>
          <div class="col-6">{{ Object.keys(game.Odds.FT[29].main.main)[1] }}</div>
        </div>
        <div class="row my-1 text-center">
          <div
            class="col-6 oddHover p-0"
            style="cursor: pointer"
            :class="{
              'btn-odd-selected': existOdd(
                game,
                'FT',
                29,
                game.Odds.FT[29].main,
                Object.values(game.Odds.FT[29].main.main)[0]
              )
            }"
            @click="processId(game, 'FT', 29, game.Odds.FT[29].main, Object.values(game.Odds.FT[29].main.main)[0])"
          >
            <template v-if="showSign(getFormat, Object.values(game.Odds.FT[29].main.main)[0][getFormat])">+</template>
            {{ Object.values(game.Odds.FT[29].main.main)[0][getFormat] }}
          </div>
          <div
            class="col-6 oddHover p-0"
            style="cursor: pointer"
            :class="{
              'btn-odd-selected': existOdd(
                game,
                'FT',
                29,
                game.Odds.FT[29].main,
                Object.values(game.Odds.FT[29].main.main)[1]
              )
            }"
            @click="processId(game, 'FT', 29, game.Odds.FT[29].main, Object.values(game.Odds.FT[29].main.main)[1])"
          >
            <template v-if="showSign(getFormat, Object.values(game.Odds.FT[29].main.main)[1][getFormat])">+</template>
            {{ Object.values(game.Odds.FT[29].main.main)[1][getFormat] }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-2 px-0" v-for="item in arrayHT[game.League.Sport.id]" :key="item">
      <div v-if="game.Odds && game.Odds.HT && game.Odds.HT[item]">
        <div class="row mb-1 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
          <span class="text-center">{{ odds[item].name }}</span>
        </div>
        <div v-for="(main, key) in Object.values(game.Odds.HT[item].main)" :key="key">
          <div class="row oddHover my-1" v-for="(odd, key2) in main" :key="key2" style="cursor: pointer">
            <span class="text-center" @click="processId(game, 'HT', item, game.Odds.HT[item].main, odd)">
              <template v-if="showSign(getFormat, odd[getFormat])">+</template>{{ odd[getFormat] }}
              <template v-if="odd.factor">({{ odd.factor }})</template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-2 px-0" v-for="item in arrayOthers[1]" :key="item">
      <div v-if="game.Odds && game.Odds.FT && game.Odds.FT[item]">
        <div class="row mb-1 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
          <span class="text-center">{{ odds[item].name }}</span>
        </div>
        <div v-for="(main, key) in Object.values(game.Odds.FT[item].main)" :key="key">
          <div class="row my-1 oddHover" v-for="(odd, key2) in main" :key="key2">
            <span
              class="text-center"
              :class="{
                'btn-odd-selected': existOdd(game, 'FT', item, game.Odds.FT[item].main, odd)
              }"
              @click="processId(game, 'FT', item, game.Odds.FT[item].main, odd)"
            >
              <template v-if="showSign(getFormat, odd[getFormat])">+</template>{{ odd[getFormat] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Odds from "../store/data/odds.json";

export default {
  data() {
    return {
      odds: Odds,
      formato: "odds",
      arrayFT: {
        1: ["1", "14", "18"],
        2: ["1", "14", "18"],
        3: ["1", "256", "258"],
        4: ["1", "16"],
        5: ["186", "187", "189"],
        16: ["1"]
      },
      arrayHT: {
        2: ["274", "275", "276"],
        3: ["274", "275", "276"]
      },
      arrayOthers: {
        1: ["186"]
      }
    };
  },
  props: {
    sport_id: null,
    game: []
  },
  computed: {
    ...mapGetters(["getLeaguesStore", "getThemelight", "getOdds", "getFormat", "getBetType", "getOddsRestricteds"])
  },
  methods: {
    ...mapActions(["processOdd", "updatePrize", "verifyOdd"]),
    processId(game, type, idOdd, keyOdd, odd) {
      let key = Object.keys(keyOdd);
      let id = game.id + "X" + type + "X" + idOdd + "X" + key + "X" + odd.outcome;

      let oddObject = {
        id,
        home: game.Team.home.name,
        away: game.Team.away.name,
        type,
        odds: odd.odds,
        odds_a: odd.odds_a,
        factor: odd.factor,
        outcome: odd.outcome,
        sport_id: game.League.Sport.id,
        idOdd,
        game_id: game.id,
        time: game.time
      };

      if (this.getBetType == "Directa") {
        let oddExisted = this.getOddsRestricteds.includes(id);

        if (oddExisted) {
          this.$store.commit("setOddsRestricteds", id);
        }
      }

      this.processOdd(oddObject);
      this.updatePrize();
    },
    existOdd(game, type, idOdd, keyOdd, odd) {
      let key = Object.keys(keyOdd);
      let id = game.id + "X" + type + "X" + idOdd + "X" + key + "X" + odd.outcome;

      return this.getOdds.find(element => element.id == id);
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    }
  }
};
</script>

<style scoped>
.btn-odd-selected {
  font-weight: 500;
  text-shadow: 1px 1px 3px black !important;
  color: #ffffff;
  background-color: #3cb202;
}
.oddHover {
  transition: all 0.25s;
  cursor: pointer;
}
.oddHover:hover {
  background-color: #f4f4f4;
  color: #343a40;
  text-shadow: 1px 1px 1px #fff;
}
</style>
