<template>
  <div class="container-juegos">
    <div v-if="this.getGamesStore.length > 0">
      <div class="text-center mt-2" :class="{ 'bg-light': getThemelight }">
        <h1>Juegos<template v-if="getGamesType == 'destacados'"> Destacados</template></h1>
      </div>
      <div class="box-games">
        <div v-for="(game, key) in this.getGamesStore" :key="key">
          <div class="text-center title-game my-2" v-if="game.showTitle">
            {{ game.League.Tournament.name }} {{ game.League.name }} ({{ game.League.Sport.name }})
          </div>
          <div class="box-game">
            <div class="row" :class="{ 'bg-light': getThemelight }">
              <div class="col-3 pr-0">
                <div class="mb-2 py-1" :class="{ 'title-light-bg text-light': getThemelight }">
                  <span class="ml-2">{{ getFechaFormat(game.time) }}</span>
                </div>
                <div class="my-1">
                  <span class="ml-2">
                    {{ game.Team.home.name }}
                  </span>
                </div>
                <div class="my-1">
                  <span class="ml-2">
                    {{ game.Team.away.name }}
                  </span>
                </div>
                <div v-if="game.League.Sport.id == 1">
                  <span class="ml-2"> Empate </span>
                </div>
              </div>
              <div class="col-9">
                <LogrosPrincipales :game="game" />
              </div>
            </div>
          </div>
          <div class="text-center my-3">
            <button
              type="button"
              class="btn-base border-green btn-jugadas"
              @click="openDetail(game)"
              :class="{ 'btn-light': getThemelight }"
            >
              <span>
                <i
                  class="fa float-left ml-2"
                  :class="{ 'fa-minus': isOpen(game.id), 'fa-plus': !isOpen(game.id) }"
                  style="line-height: 1.5em"
                ></i>
                JUGADAS</span
              >
            </button>
          </div>

          <div
            class="mt-2"
            style="border: 10px solid #212529"
            v-if="isOpen(game.id)"
            :class="{
              'animate__animated animate__bounceOutUp': !isOpen(game.id),
              'animate__animated animate__bounceInUp': isOpen(game.id),
              'bg-light': getThemelight
            }"
          >
            <LogrosSecundarios :sport_id="game.League.Sport.id" :game="game" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card dark-bg text-light text-center p-4 mt-5" :class="{ 'card-bg-light': getThemelight }">
        <h4>No Hay Juegos Disponibles</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LogrosPrincipales from "./LogrosPrincipales.vue";
import LogrosSecundarios from "./LogrosSecundarios.vue";
import moment from "moment";
import Odds from "../store/data/odds.json";

export default {
  data() {
    return {
      odds: Odds,
      leagueDiff: null,
      detailsOpened: []
    };
  },
  computed: {
    ...mapGetters(["getGamesType", "getGamesStore", "getThemelight"])
  },
  methods: {
    getFechaFormat(f) {
      return moment(f).format("DD/MM/YYYY hh:mm A");
    },
    openDetail(game) {
      let index = this.detailsOpened.indexOf(game.id);

      if (index > -1) {
        this.detailsOpened.splice(index, 1);
      } else {
        this.detailsOpened = [];
        this.detailsOpened.push(game.id);
      }
    },
    isOpen(id) {
      return this.detailsOpened.includes(id);
    }
  },
  beforeUpdate() {
    let gameIdDiff = null;

    this.getGamesStore.map(element => {
      element.showTitle = element.League.id == gameIdDiff ? false : true;

      gameIdDiff = element.League.id;

      return element.showTitle;
    });
  },
  components: {
    LogrosPrincipales,
    LogrosSecundarios
  }
};
</script>

<style scoped>
.box-games {
  outline: 8px groove rgba(143, 164, 145, 0.06);
  outline-offset: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 40em;
}
.title-game {
  margin-top: 1em !important;
  border-top: solid 1px #ffffff7d;
  padding-top: 0.5em;
  border-top-style: dashed;
  border-top-width: thin;
}
.container-juegos {
  background-color: #41474e !important;
  min-height: 10em;
  width: 100%;
  left: 0;
}

.box-game {
  background-color: #32383e;
  text-align: left;
  /* padding: 0px 1em !important; */
  font-size: 12px;
  min-height: 22px;
  text-transform: uppercase;
}
.card-bg-light {
  background-color: #5d75cb;
}

.btn-light {
  background-image: linear-gradient(127deg, #02256b, #007afe) !important;
  background-color: transparent;
}
.btn-light span {
  background-image: linear-gradient(127deg, #007bff, #011f62);
}
.btn-light:hover {
  background-image: linear-gradient(0deg, #007bff, #011f62);
}

.table.table-top-details.table-light {
  background-color: #7f80824d;
}
</style>

<style>
.title-light-bg {
  background-color: #011f62;
}
</style>
