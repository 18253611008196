var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{class:{
    dark: !_vm.getThemelight,
    light: _vm.getThemelight
  },attrs:{"id":"modal-tickets","header-bg-variant":!this.getThemelight ? 'dark' : 'light',"body-bg-variant":!this.getThemelight ? 'dark' : 'light',"footer-bg-variant":!this.getThemelight ? 'dark' : 'light',"size":"lg","centered":"","scrollable":"","title":"REPORTE DE TICKETS JUGADOS","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
  var close = ref.close;
return [_c('h5',{staticClass:"modal-title mr-4"},[_vm._v("REPORTE DE TICKETS JUGADOS")]),_c('vc-date-picker',{class:{
        'fecha-ticket ml-2 mr-2': true,
        'bg-dark': !_vm.getThemelight,
        'bg-light': _vm.getThemelight
      },attrs:{"input-debounce":500,"masks":_vm.masksPicker,"max-date":new Date()},on:{"input":_vm.updateValuePickerTickets},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var inputValue = ref.inputValue;
      var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({class:{
            'border rounded form-control text-center bg-input-date': true,
            'bg-dark': !_vm.getThemelight,
            'bg-light': _vm.getThemelight
          },domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.fechaFiltroTicket),callback:function ($$v) {_vm.fechaFiltroTicket=$$v},expression:"fechaFiltroTicket"}}),_c('b-form-select',{staticClass:"input-modal",attrs:{"options":_vm.optionsStatus},on:{"change":_vm.changeStatusFilter},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.numberFilter),expression:"numberFilter"},{name:"debounce",rawName:"v-debounce:1s",value:(_vm.filter),expression:"filter",arg:"1s"}],staticClass:"form-control input-modal ml-2 mr-2",attrs:{"placeholder":"Numero"},domProps:{"value":(_vm.numberFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.numberFilter=$event.target.value}}}),_c('b-form-select',{staticClass:"input-modal",attrs:{"options":_vm.optionsCuotas},model:{value:(_vm.selectedCuotas),callback:function ($$v) {_vm.selectedCuotas=$$v},expression:"selectedCuotas"}}),_c('b-button',{staticClass:"float-right ml-2 mr-2 btn-azul-rey-1",attrs:{"variant":"success"},on:{"click":_vm.filter}},[_vm._v("Filtrar")]),_c('b-button',{staticClass:"float-right ml-2 mr-2",attrs:{"variant":"secondary"},on:{"click":_vm.clearFilters}},[_vm._v("Limpiar")]),_c('b-button',{staticClass:"float-right close",on:{"click":function($event){return close()}}},[_vm._v("X")])]}}])},[_c('tickets-table',{attrs:{"page":this.pageResults,"perPage":this.limitResults}}),(this.getTickets && this.getTickets.total)?_c('b-pagination',{class:{
      'customPagination float-left mt-1': true,
      'bg-dark': !_vm.getThemelight,
      'bg-light': _vm.getThemelight
    },attrs:{"size":"sm","per-page":this.limitResults,"limit":3,"total-rows":parseInt(this.getTickets.total),"custom":"size","first-number":"","last-number":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }