<template>
  <div :class="{ 'bg-light text-dark': getThemelight, 'bg-secondary-odd': !getThemelight }">
    <div class="row text-center">
      <div class="col-4" :class="{ 'title-light-bg text-light': getThemelight }">
        Juego Completo (FT) {{ game.League.Sport.id }}
      </div>
      <div class="col-4" :class="{ 'title-light-bg text-light': getThemelight }" v-if="game.Odds.HT">
        1ra Mitad (HT)
      </div>
      <div class="col-4" :class="{ 'title-light-bg text-light': getThemelight }" v-if="game.Odds.ST">
        2da Mitad (ST)
      </div>
    </div>
    <div class="flexbox">
      <div class="row text-center">
        <div class="col-4 border-odds" v-for="period in arrayPeriodsHalves" :key="period">
          <div class="row" v-if="game.Odds && game.Odds[period]">
            <div v-for="(odd, key) in Object.entries(game.Odds[period])" :key="key" class="col-12 text-center mb-1">
              <div>
                <div class="my-1" :class="{ 'odd-title-light-bg': getThemelight }">{{ odd[1].name }}</div>
                <div v-for="(other, key2) in Object.values(odd[1].main)" :key="key2">
                  <div
                    v-if="Object.values(other).length == 2"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(other)"
                      :key="key3"
                      class="col-6 sel-odd p-0"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div class="d-flex justify-content-around p-1">
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data['odds'])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 3"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(other)"
                      :key="key3"
                      class="col-4 sel-odd p-0"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div class="d-flex justify-content-around p-1">
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(other)"
                      :key="key3"
                      class="col-4 sel-odd p-0"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div class="d-flex justify-content-around p-1">
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length > 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(other)"
                      :key="key3"
                      class="col-4 sel-odd p-0"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div class="d-flex justify-content-around p-1">
                        <b>{{ data.outcome }}</b>
                        <span class="m-auto">
                          <template v-if="showSign(getFormat, data[getFormat])">+</template>{{ data[getFormat] }}</span
                        >
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flexbox mt-3 text-center py-1"
      v-if="game.Odds.FQ"
      :class="{ 'bg-light text-dark': getThemelight, 'bg-secondary-odd': !getThemelight }"
    >
      <div class="row">
        <div class="col-3" :class="{ 'title-light-bg text-light': getThemelight }">1er Cuarto (1st Prd)</div>
        <div class="col-3" :class="{ 'title-light-bg text-light': getThemelight }" v-if="game.Odds.SQ">
          2do Cuarto (2nd Prd)
        </div>
        <div class="col-3" :class="{ 'title-light-bg text-light': getThemelight }" v-if="game.Odds.TQ">
          3er Cuarto (3rd Prd)
        </div>
        <div class="col-3" :class="{ 'title-light-bg text-light': getThemelight }" v-if="game.Odds.QQ">
          4to Cuarto (4th Prd)
        </div>
      </div>
      <div class="row">
        <div class="col-3 border-odds" v-for="period in arrayPeriodsQuaters" :key="period">
          <div class="row" style="margin-left: -0.2em" v-if="game.Odds && game.Odds[period]">
            <div v-for="(odd, key) in Object.entries(game.Odds[period])" :key="key" class="col-12 text-center mb-1">
              <div class="mb-2">{{ odd[1].name }}</div>
              <div v-if="odd[1].other">
                <div v-for="(item, key2) in Object.values(odd[1].other)" :key="key2">
                  <div
                    v-if="Object.values(item).length == 2"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-6 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length == 3"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length == 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length > 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="odd[1].main">
                <div v-for="(item, key2) in Object.values(odd[1].main)" :key="key2">
                  <div
                    v-if="Object.values(item).length == 2"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-6 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length == 3"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length == 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(item).length > 4"
                    class="row box-odds"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div
                      v-for="(data, key3) in Object.values(item)"
                      :key="key3"
                      class="col-4 sel-odd"
                      :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      @click="processId(game, period, odd[0], data)"
                    >
                      <div>
                        <b>{{ data.outcome }}</b> {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      arrayPeriodsHalves: ["FT", "HT", "ST"],
      arrayPeriodsQuaters: ["FQ", "SQ", "TQ", "QQ"]
    };
  },
  props: {
    game: [],
    sport_id: null
  },
  computed: {
    ...mapGetters(["getOdds", "getFormat", "getThemelight"])
  },
  methods: {
    ...mapActions(["processOdd", "updatePrize"]),
    processId(game, type, idOdd, odd) {
      let key = game.Odds[type][idOdd].other
        ? Object.keys(game.Odds[type][idOdd].other)
        : Object.keys(game.Odds[type][idOdd].main);
      let id = game.id + "X" + type + "X" + idOdd + "X" + key + "X" + odd.outcome;

      let oddObject = {
        id,
        home: game.Team.home.name,
        away: game.Team.away.name,
        type,
        odds: odd.odds,
        odds_a: odd.odds_a,
        factor: odd.factor,
        outcome: odd.outcome,
        sport_id: game.League.Sport.id,
        idOdd,
        game_id: game.id,
        time: game.time
      };

      if (this.getBetType == "Directa") {
        let oddExisted = this.getOddsRestricteds.includes(id);

        if (oddExisted) {
          this.$store.commit("setOddsRestricteds", id);
        }
      }

      this.processOdd(oddObject);
      this.updatePrize();
    },
    existOdd(game, type, item, odd) {
      let key = game.Odds[type][item].other
        ? Object.keys(game.Odds[type][item].other)
        : Object.keys(game.Odds[type][item].main);
      let id = game.id + "X" + type + "X" + item + "X" + key + "X" + odd.outcome;

      return this.getOdds.find(element => element.id == id);
    },
    changeFormat(oddFormat) {
      this.$store.commit("setFormat", oddFormat);
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    }
  }
};
</script>

<style scoped>
.bg-secondary-odd {
  background-color: #41474e;
  max-height: 800px;
}
.btn-odd-selected {
  font-weight: 500;
  text-shadow: 1px 1px 3px black !important;
  color: #ffffff;
  background-color: #3cb202;
}
.col-4 {
  border-bottom: 1px solid #41474e;
}
.col-6 {
  border-bottom: 1px solid #41474e;
}

.box-odds {
  background-color: #32383e;
  color: white;
  font-size: 14px;
}
.sel-odd {
  cursor: pointer;
}

.odd-title-light-bg {
  background-color: rgb(229 229 229 / 58%);
}

.border-odds {
  border: 1px solid #212529;
  border-top: 4px solid #212529;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sel-odd:hover {
  background: #ff512f;
  background: -webkit-linear-gradient(to right, #f09819, #ff512f);
  background: linear-gradient(to right, #f09819, #ff512f);

  -webkit-animation: fadein 0.2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.2s;
  /* Internet Explorer */
  -o-animation: fadein 0.2s;
  /* Opera < 12.1 */
  animation: fadein 0.2s;
}
</style>
