<template>
  <div class="mb-5">
    <div class="wrapper" style="min-height: 100px; height: auto">
      <!-- Sidebar -->
      <nav
        id="sidebar"
        :class="{
          'sidebar-light-bg': this.getThemelight,
          'sidebar-dark-bg': !this.getThemelight,
          active: menuOculto && !menuHover
        }"
      >
        <div class="text-center my-2">
          <button
            type="button"
            id="sidebarCollapse"
            :class="{ 'btn-light': getThemelight }"
            class="btn btn-secondary text-nowrap"
            @click="manageMenu()"
          >
            <i id="arrow" class="fa fa-angle-double-left"></i>
            <span id="ocultar" class="animate__animated animate__bounceInUp ml-2">Ocultar</span>
          </button>
        </div>

        <div @mouseover="showMenu()" @mouseleave="hideMenu()">
          <div class="d-flex justify-content-around flex-nowrap btn-menu-lateral">
            <div>
              <div class="btn-stream text-nowrap px-2" v-b-modal.modal-streaming id="btn-streaming">
                <img class="img-steaming" v-lazy="'/img/livestream.webp'" />
                <span id="idStreaming"> Streaming </span>
              </div>
            </div>
            <div
              id="cont-ticket"
              class="btn-tickets text-nowrap animate__animated animate__fadeInBottomLeft"
              v-b-modal.modal-tickets
            >
              <i id="imgTicket" class="fas fa-print d-none" style="font-size: 20px"></i>
              <span id="idTicket">Ver tickets jugados</span>
            </div>
          </div>

          <div class="row flex-wrap btn-menu-lateral text-center my-0" id="filterTemp">
            <div class="col-4 p-2" @click="filterTemp('Hoy')" :class="{ active: this.periodActive == 'Hoy' }">
              <span>Hoy</span>
            </div>
            <div class="col-4 p-2" @click="filterTemp('Todas')" :class="{ active: this.periodActive == 'Todas' }">
              <span>Todas</span>
            </div>
            <div class="col-4 p-2" @click="filterTemp('Fecha')" :class="{ active: this.periodActive == 'Fecha' }">
              <span>Fecha</span>
            </div>
          </div>

          <div v-if="this.periodActive == 'Fecha'">
            <vc-date-picker
              v-model="dateTargetPicker"
              :input-debounce="500"
              :min-date="new Date()"
              class="w-100"
              :masks="masksPicker"
              @input="filterTemp('Fecha')"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <form action="">
                  <input
                    :class="{
                      'border rounded form-control text-center bg-input-date': true,
                      'bg-dark': !getThemelight,
                      'bg-light': getThemelight
                    }"
                    :value="inputValue"
                    v-on="inputEvents"
                    autocomplete="off"
                  />
                </form>
              </template>
            </vc-date-picker>
          </div>
          <details
            @click="detail"
            id="detailDeporte"
            class="list-unstyled bg-sport"
            v-for="(deporte, key) in getLeaguesStore"
            :key="key"
            :class="{
              'sidebar-light-bg': getThemelight,
              'sidebar-dark-bg': !getThemelight
            }"
          >
            <summary class="text-nowrap">
              <img class="mx-2 my-2" :src="renderIcono(key)" width="20" />
              <span id="sport" class="animate__animated animate__bounceInUp">{{ key }}</span>
            </summary>
            <div class="box-tournament">
              <details
                class="list-unstyled bg-tournament"
                v-for="(torneo, key2) in deporte"
                :key="key2"
                @click="detailTournament"
                id="detailTournament"
              >
                <summary class="ml-3 text-left">{{ key2 }}</summary>
                <div>
                  <details
                    class="list-unstyled bg-league animate__animated animate__fadeIn"
                    id="liga"
                    v-for="(liga, key3) in torneo"
                    :key="key3"
                    @click="$emit('ligaId', key3)"
                  >
                    <summary class="ml-4">{{ liga }}</summary>
                  </details>
                </div>
              </details>
            </div>
          </details>
        </div>
      </nav>
    </div>

    <ModalStreaming />
    <ModalTicketsJugados />
  </div>
</template>

<script>
import deportes from "@/store/data/deportes.json";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ModalStreaming from "./ModalSreaming.vue";
import ModalTicketsJugados from "./ModalTicketsJugados.vue";

export default {
  async mounted() {
    await this.actionLeagues();
    console.log("Ligas: ", this.getLeaguesStore);
    this.configMenu();
  },
  computed: {
    ...mapGetters(["getLeaguesStore", "getThemelight"])
  },
  components: {
    ModalStreaming,
    ModalTicketsJugados
  },
  data() {
    return {
      deportes: [],
      menuOculto: false,
      menuHover: false,
      mostrarModal: false,
      verCalendario: false,
      masksPicker: { input: "DD-MM-YYYY" },
      dateTargetPicker: moment().format("YYYY-MM-DD"),
      periodActive: "Todas",
      strTeam: null
    };
  },
  methods: {
    ...mapActions(["actionLeagues"]),
    configMenu() {
      let me = this;
      $(document).ready(function() {
        $("#sidebarCollapse").on("click", function() {
          me.toggleMenu();
        });
      });
    },
    renderIcono(icono) {
      const result = deportes.find(x => x.deporte == icono);
      return result && result.path ? result.path : "img/logo/img2x.png";
    },
    detail() {
      $("details#detailDeporte").on("click", function() {
        $("#detailDeporte[open]")
          .not(this)
          .removeAttr("open");
      });
    },
    detailTournament() {
      $("details#detailTournament").on("click", function() {
        $("#detailTournament[open]")
          .not(this)
          .removeAttr("open");
      });
    },
    //Animaciones del menu
    manageMenu() {
      this.menuOculto = !this.menuOculto;
      this.menuHover = false;
      $("details[open]").removeAttr("open");
    },
    showMenu() {
      if (this.menuOculto && !this.menuHover) {
        this.toggleMenu();
        this.menuHover = true;
      }
    },
    hideMenu() {
      if (this.menuOculto && this.menuHover) {
        this.toggleMenu();
        this.menuHover = false;
        $("details[open]").removeAttr("open");
      }
    },
    toggleMenu() {
      //$("#sidebar").toggleClass("active");
      $("summary span")
        .toggleClass("animate__animated animate__zoomOut d-none")
        .toggleClass("animate__animated animate__bounceInUp");
      $("#arrow")
        .toggleClass("fa fa-angle-double-left")
        .toggleClass("fa fa-angle-double-right");
      $("#ocultar")
        .toggleClass("animate__animated animate__zoomOut d-none")
        .toggleClass("animate__animated animate__bounceInUp");
      $("#idStreaming")
        .toggleClass("animate__animated animate__zoomOut d-none")
        .toggleClass("animate__animated animate__bounceInUp");
      $("#idTicket")
        .toggleClass("animate__animated animate__zoomOut d-none")
        .toggleClass("animate__animated animate__bounceInUp");
      $("#cont-ticket")
        .toggleClass("animate__animated animate__fadeInBottomRigth")
        .toggleClass("animate__animated animate__fadeOut");
      $("#filterTemp").toggleClass("animate__animated animate__fadeOutLeft");
    },

    //Funciones de taquilla
    filterTemp(period) {
      this.verCalendario = false;
      this.periodActive = period;
      let paramDate = "";

      if (period == "Fecha") paramDate = `&date=${moment(this.dateTargetPicker).format("YYYY-MM-DD")}`;
      if (period == "Hoy") paramDate = `&date=${moment().format("YYYY-MM-DD")}`;

      this.actionLeagues(paramDate);
    }
  }
};
</script>

<style scoped>
.animate__fadeOut {
  height: 0;
  --animate-duration: 0.5s;
}
.animate__fadeOutLeft {
  height: 0;
  --animate-duration: 0.5s;
}
.animate__fadeInBottomRight {
  --animate-duration: 0.5s;
}
.container-2-btns {
  width: 100%;
  padding-top: 0px !important;
  outline-offset: 0em;
  display: flex;
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
#sidebar {
  background-color: #eee;
  /* background-image: linear-gradient(127deg, #32383e, #00000078); */
  color: #fff;
  transition: all 0.5s;
}
#sidebar {
  min-width: 250px;
  max-width: 250px;
}
#sidebar.active {
  min-width: 40px;
  max-width: 40px;
}
.sidebar-light-bg {
  background-image: linear-gradient(127deg, #011f62, #011f62);
}
.sidebar-light-bg #filterTemp .col-4:hover {
  background: linear-gradient(90deg, #f09819, #ff512f);
}
.sidebar-dark-bg {
  background-image: linear-gradient(127deg, #32383e, #00000078);
}

#sidebar div button {
  min-width: 140px;
  max-width: 140px;
  transition: all 0.5s;
}
#sidebar.active div button {
  min-width: 25px;
  max-width: 25px;
  padding-left: 5px;
}

a[data-toggle="collapse"] {
  position: relative;
}
.bg-sport {
  background-color: #212529 !important;
}
.bg-sport:hover {
  background: #ff512f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f09819, #ff512f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f09819, #ff512f);
}
.bg-tournament {
  background-color: #646769 !important;
}
.bg-tournament:hover {
  background-color: #343a40 !important;
}
.box-tournament {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 25em;
}
.bg-league {
  background-color: #757a7d !important;
}

#liga:hover {
  padding-left: 10px;
  transition: padding-left 200ms ease-out 100ms;
  color: white;
  font-weight: 600;
}

.btn-stream {
  background: linear-gradient(black, black) padding-box, linear-gradient(to right, #6bff00, #0d6403) border-box;
  border-radius: 10%;
  border: 4px solid transparent;
  width: 100%;
}

.btn-stream:hover {
  background: linear-gradient(to right, #6bff00, #0d6403) border-box !important;
}

.btn-tickets {
  background: linear-gradient(to right, #f09819, #ff512f) !important;
  padding: 6px;
  margin: auto;
  font-size: 0.85em;
  text-transform: uppercase;
  font-weight: 500;
}

.btn-tickets > span:hover {
  outline-color: #b2b2b2;
  color: #fff !important;
  text-shadow: #000 1px 1px 3px;
  background-color: transparent !important;
}

.btn-menu-lateral {
  font-weight: 500;
  /* background: linear-gradient(to right, #f09819, #ff512f) !important; */
  cursor: pointer;
  font-size: 0.8em;
}

.btn-menu-lateral > .col-4:hover {
  background-color: #eee;
  background-image: linear-gradient(127deg, #32383e, #00000078);
  color: white !important;
}
</style>

<style>
/* Estilos de scroll */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
