<template>
  <div
    class="btn-floating justify-content-between"
    :class="{ 'width-active': !getFloatTicket, 'width-noactive': getFloatTicket }"
  >
    <button class="btn btn-dark pt-1" @click="minTickets">
      <i class="fas fa-compress-arrows-alt"></i>
    </button>
    <div
      class="sel-apuestas"
      @click="floatTickets"
      :class="{ 'wide-small': getFloatTicket, 'min-tickets': !getFloatTicket }"
    >
      <i
        class="fas fa-lg mx-2"
        style="font-size: 20px"
        :class="{ 'fa-angle-up': !getFloatTicket, 'fa-angle-down': getFloatTicket }"
      ></i>
      <label :class="{ 'animate__animated animate__fadeOutRight': getFloatTicket }"
        >Selección de Apuestas <span class="badge bg-white ml-2">{{ this.getOdds.length }}</span></label
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getMinTicket", "getOdds", "getFloatTicket"])
  },
  methods: {
    minTickets() {
      this.$store.commit("setMinTicket", true);
      this.$store.commit("setFloatTicket", false);
    },
    floatTickets() {
      //this.detailsActive = !this.detailsActive;
      this.$store.commit("setFloatTicket", !this.getFloatTicket);
    }
  }
};
</script>

<style scoped>
.wide-small {
  width: 13%;
  background: linear-gradient(to right, #f09819, #ff512f);
  transition: 200ms linear;
  margin-right: 10px;
}
.width-active {
  width: 20%;
  transition: 0.5s;
}
.width-noactive {
  width: 16%;
  transition: 1s;
}
.btn-floating {
  position: fixed;
  bottom: 2%;
  right: 0px;
  height: 2.5em;
  background-clip: border-box;
  border-radius: 2em;
  z-index: 4 !important;
}

.min-tickets {
  width: 100%;
  background: linear-gradient(to right, #f09819, #ff512f);
  transition: 200ms linear;
}
.sel-apuestas:hover {
  cursor: pointer;
  background: linear-gradient(to right, #f09819, #ff512f);
  border: none;
  text-shadow: #000 1px 1px 3px;
}
.contenedor-detalles {
  display: block;
  position: fixed;
  background: rgba(49, 55, 61, 0.8);
  color: #fff;
  z-index: 100;
  width: 360px;
  height: 10vh;
  top: 96px;
  right: 0%;
}

.cabecera-seleccion-apuestas {
  width: 100% !important;
  height: 3em;
  display: flex;
  padding: 0.3em;
  overflow-y: hidden;
  background-color: #6c6c6c;
}
</style>
