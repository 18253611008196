<template>
  <div class="d-flex mt-taquilla text-light" id="taquilla">
    <MenuLateral @ligaId="ligaId" />
    <Juegos class="mx-2" :class="{ 'bg-light': getThemelight }" v-if="getGamesStore" />
    <Tickets id="tickets-module" :class="{ 'animate__animated animate__fadeOutRight': !getMinTicket }" />
    <TicketsMin
      id="min-tickets"
      class="d-flex"
      :class="{
        'animate__animated animate__fadeInUp': !getMinTicket,
        'animate__animated animate__fadeOutDown': getMinTicket
      }"
    />
    <TicketsFloating
      id="float-tickets"
      style="position: fixed"
      :class="{
        'animate__animated animate__fadeInUp': getFloatTicket,
        'animate__animated animate__fadeOutDown': !getFloatTicket
      }"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuLateral from "../components/MenuLateral.vue";
import Juegos from "../components/Juegos.vue";
import Tickets from "../components/Tickets.vue";
import TicketsMin from "../components/TicketsMin.vue";
import TicketsFloating from "../components/TicketsFloating.vue";
import moment from "moment";
import { chatMixin } from "../../../mixins/chatMixin.js";

export default {
  mixins: [chatMixin],
  computed: {
    ...mapGetters(["getGamesStore", "getMinTicket", "getFloatTicket", "getThemelight", "getRestrictions"])
  },
  methods: {
    ...mapActions(["actionGames", "actionRestrictions"]),
    ligaId(id) {
      const fecha = moment().format("YYYY-MM-DD");

      $("html, #taquilla").animate({ scrollTop: 0 }, 800);

      let params = {
        id,
        fecha
      };

      this.actionGames(params);
    }
  },
  components: {
    MenuLateral,
    Juegos,
    Tickets,
    TicketsMin,
    TicketsFloating
  },
  mounted() {
    this.hideChat();
    this.$store.commit("setMinTicket", true);
    this.$store.commit("setFloatTicket", false);
  },
  async created() {
    await this.actionGames();
    await this.actionRestrictions();
  }
};
</script>

<style scoped>
.mt-taquilla {
  padding-top: 7em;
}
#tickets-module.animate__fadeOutRight {
  min-width: 40px;
  max-width: 40px;
  transition: 2s;
}
#tickets-module {
  min-width: 250px;
  max-width: 250px;
  transition: 0.1s;
}
#float-tickets {
  position: absolute;
  display: flex;
  right: 0;
}
#min-tickets {
  display: none;
}
#min-tickets.animate__fadeInUp {
  display: flex;
}
#float-tickets.animate__fadeInUp {
  right: 0;
}
</style>
