<template>
  <b-modal
    id="modal-tickets"
    :header-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    :body-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    :footer-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    :class="{
      dark: !getThemelight,
      light: getThemelight
    }"
    size="lg"
    centered
    scrollable
    title="REPORTE DE TICKETS JUGADOS"
    hide-footer
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title mr-4">REPORTE DE TICKETS JUGADOS</h5>
      <vc-date-picker
        v-model="fechaFiltroTicket"
        :input-debounce="500"
        :class="{
          'fecha-ticket ml-2 mr-2': true,
          'bg-dark': !getThemelight,
          'bg-light': getThemelight
        }"
        :masks="masksPicker"
        :max-date="new Date()"
        @input="updateValuePickerTickets"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            :class="{
              'border rounded form-control text-center bg-input-date': true,
              'bg-dark': !getThemelight,
              'bg-light': getThemelight
            }"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </vc-date-picker>
      <b-form-select
        class="input-modal"
        v-model="selectedStatus"
        @change="changeStatusFilter"
        :options="optionsStatus"
      ></b-form-select>
      <input
        placeholder="Numero"
        v-model="numberFilter"
        class="form-control input-modal ml-2 mr-2"
        v-debounce:1s="filter"
      />
      <b-form-select class="input-modal" v-model="selectedCuotas" :options="optionsCuotas"></b-form-select>
      <b-button class="float-right ml-2 mr-2 btn-azul-rey-1" variant="success" @click="filter">Filtrar</b-button>
      <b-button class="float-right ml-2 mr-2" variant="secondary" @click="clearFilters">Limpiar</b-button>
      <b-button class="float-right close" @click="close()">X</b-button>
    </template>
    <tickets-table :page="this.pageResults" :perPage="this.limitResults"></tickets-table>
    <b-pagination
      v-if="this.getTickets && this.getTickets.total"
      size="sm"
      :per-page="this.limitResults"
      :limit="3"
      :total-rows="parseInt(this.getTickets.total)"
      v-model="page"
      custom="size"
      :class="{
        'customPagination float-left mt-1': true,
        'bg-dark': !getThemelight,
        'bg-light': getThemelight
      }"
      first-number
      last-number
      @input="changePage"
    />
    <!-- <b-button class="mt-1 float-right" sm @click="$bvModal.hide('modal-tickets')">Cerrar</b-button> -->
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import TicketsTable from "@/components/tables/TicketsTable.vue";
import moment from "moment";

export default {
  data() {
    return {
      fechaFiltroTicket: null,
      masksPicker: { input: "DD-MM-YYYY" },
      selectedStatus: 0,
      optionsStatus: [
        { value: 0, text: "TODOS" },
        { value: 1, text: "PENDIENTE" },
        { value: 2, text: "GANADOR" },
        { value: 3, text: "PERDEDOR" },
        { value: 5, text: "ANULADO" }
      ],
      numberFilter: null,
      selectedCuotas: "odd",
      optionsCuotas: [
        { value: "odd", text: "Americano" },
        { value: "odd_a", text: "Decimal" }
      ],
      pageResults: 1,
      limitResults: 15,
      page: 1
    };
  },
  computed: {
    ...mapGetters(["getThemelight", "getTickets"])
  },
  components: {
    TicketsTable
  },
  methods: {
    async changePage(pag) {
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", { page: pag });
      this.$store.commit("hideLoader");
    },
    async updateValuePickerTickets(v) {
      this.$store.commit("showLoader");

      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: this.selectedStatus,
        number: this.numberFilter,
        since: moment(v).format("YYYY-MM-DD"),
        until: moment(v).format("YYYY-MM-DD")
      });

      this.$store.commit("hideLoader");
    },
    async changeStatusFilter(evt) {
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: evt
      });
      this.$store.commit("hideLoader");
    },
    async filter() {
      this.$store.commit("setTickets", []);
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: this.selectedStatus,
        number: this.numberFilter
      });
      this.$store.commit("hideLoader");
    },
    async clearFilters() {
      //this.page = 1;
      this.selectedStatus = 0;
      this.numberFilter = null;
      this.inputValue = "";
      //this.dateTarget = null;
      //this.dateTargetPicker = null;
      //this.fechaFiltroTicket = null;
      //this.$store.commit("showLoader");
      //await this.$store.dispatch("getTicketsClientAsync");
      //this.$store.commit("hideLoader");
    }
  }
};
</script>

<style scoped>
.fecha-ticket {
  max-width: 150px;
  max-height: 38px;
  padding: 0px;
  margin: 0px;
}

.fecha-ticket > input {
  max-height: 40px;
}

.bg-input-date {
  background-color: rgb(33 36 40) !important;
  font-family: unset;
  font-weight: 200;
  font-size: 1.5em;
  border-style: dotted !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.input-modal {
  max-width: 150px;
}
</style>
