<template>
  <div>
    <!-- Modal Live -->
    <b-modal
      id="modal-streaming"
      title="Video Streaming"
      name="video-streaming"
      size="lg"
      ok-title="Minimizar"
      ok-variant="warning"
      cancel-title="Cerrar"
      cancel-variant="default"
      no-close-on-backdrop
      draggable=".contenedor-modal-streaming"
      scrollable
      v-on:show="mostrandoModalStreaming"
    >
      <div class="contenedor-modal-streaming">
        <b-tabs nav-wrapper-class="mt-3" v-model="tabIndex">
          <b-tab title="En vivo" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.online" v-bind:key="item.id" class="li-custom" @click="watchVideo(item)">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Proximos" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.next" v-bind:key="item.id" class="li-custom">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Video" title-link-class="color-tabs">
            <h3 class="text-center">{{ nameGame }}</h3>
            <iframe :src="urlgame" width="100%" height="500px"></iframe>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabIndex: 0,
      urlgame: null,
      nameGame: null,
      videoR: false,
      btnSteamingideo: "Streaming",
      selectedStatus: 0,
      page: 1,
      numberFilter: null
    };
  },
  computed: {
    ...mapGetters(["getVideo"])
  },
  methods: {
    mostrandoModalStreaming() {
      return true;
    },
    watchVideo(e) {
      this.tabIndex = 2;
      this.urlgame = e.link;
      this.nameGame = e.name;
      this.videoR = true;
      this.btnSteamingideo = "Reproduciendo";
    }
  },
  async mounted() {
    await this.$store.dispatch("getTicketsClientAsync", {
      page: this.page,
      status: this.selectedStatus,
      number: this.numberFilter
    });
  }
};
</script>

<style></style>
