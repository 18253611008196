<template>
  <div class="contenedor-floating" :class="{ 'contenedor-floating-light': getThemelight }">
    <section>
      <div class="text-center my-3">
        <h5>Selección de apuestas</h5>
      </div>

      <div>
        <div class="d-flex btn-group">
          <button
            :class="{
              'fc-prev-button btn btn-dark activadores-parley-directas': true,
              active: getFormat == 'odds'
            }"
            type="button"
            aria-label="prev"
            @click="changeFormat('odds')"
          >
            <span class="text-option">Americano</span>
          </button>
          <button
            :class="{
              'fc-prev-button btn btn-dark activadores-parley-directas': true,
              active: getFormat == 'odds_a'
            }"
            type="button"
            aria-label="next"
            @click="changeFormat('odds_a')"
          >
            <span class="text-option">Decimal</span>
          </button>
        </div>
        <div class="my-2">
          <i aria-hidden="true" class="fas fa-inbox mx-2"></i>Cantidad Apuestas
          <span class="badge bg-white ml-2">{{ this.getOdds.length }}</span>
        </div>
      </div>
      <div style="background-color: #31373d !important">
        <div class="d-flex btn-group mt-1 border-bottom border-top border-light">
          <button
            type="button"
            :class="{
              'fc-prev-button btn btn-dark activadores-formato': true,
              selected: getBetType == 'Parley'
            }"
            @click="$store.dispatch('verifyOdds')"
          >
            <span class="text-option">Parley</span>
          </button>

          <button
            type="button"
            :class="{
              'fc-prev-button btn btn-dark activadores-formato': true,
              selected: getBetType == 'Directa'
            }"
            @click="$store.commit('setBetType', 'Directa')"
          >
            <span class="text-option">Directa</span>
          </button>
        </div>
      </div>
      <section>
        <div class="cantidad-section">
          <label for="input-cantidad" class="label-monto">
            <b>Monto de la Apuesta</b>
          </label>
          <form>
            <input
              type="text"
              style="margin: 0.5em 0"
              class="input-cantidad"
              v-model="monto"
              @keypress="isNumber"
              :class="{ 'input-cantidad-light': getThemelight }"
            />
          </form>

          <button
            class="btn btn-app bg-danger icon-trash btn-clear"
            v-b-tooltip.hover.left="'Limpiar Todas las Apuestas'"
            @click="cleanData"
            :disabled="getOdds.length == 0"
          >
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="cantidad-premio" v-if="getBetType == 'Parley'">
          <label class="label-title-premio">PREMIO</label>
          <label class="label-cantidad-premio">{{ getPrize }}</label>
        </div>
        <button
          :class="{
            'btn btn-block bg-gradient-primary btn-xs btn-generar mb-1': true
          }"
        >
          Generar Apuesta
        </button>
        <div style="overflow-y: auto; max-height: 18em">
          <div
            class="callout callout-success bg-dark p-2 text-uppercase"
            :class="{
              'bg-light text-dark': getThemelight,
              'callout-danger bg-restringida': restrictedParley(item.id) && getMarkRestricted,
              'callout-success': !restrictedParley(item.id) || !getMarkRestricted
            }"
            v-for="(item, key) in getOdds"
            :key="key"
          >
            <div class="d-flex m-0 py-0 height-call text-center">
              <!-- <div class="m-auto p-0"><h5>VS</h5></div> -->
              <div class="align-self-center flex-wrap p-0 w-50">
                <div class="mb-1" style="font-size: 10px">{{ item.home }}</div>
                <div style="font-size: 10px">{{ item.away }}</div>
              </div>
              <div class="m-auto p-0 w-25">
                <div class="mb-1 p-0" style="font-size: 10px">{{ item.outcome }} ( {{ item.type }} )</div>
                <div class="p-0" style="font-size: 10px">
                  <template v-if="showSign(getFormat, item[getFormat])">+</template>{{ item[getFormat] }}
                  <template v-if="item.factor">({{ item.factor }})</template>
                </div>
              </div>
              <div class="m-auto p-0 w-25">
                <div class="btn btn-trash pb-3" v-b-tooltip.hover.left="'Borrar esta apuesta'" @click="deleteOdd(item)">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div class="premio-individual mt-2" v-if="getBetType == 'Directa'">
              <label class="label-title-premio">PREMIO</label>
              <label class="label-cantidad-premio">{{ item.prize }}</label>
            </div>
          </div>
        </div>

        <!-- <button class="btn text-light btn-float" @click="hideTickets">
          <i class="fas fa-expand-arrows-alt"></i>
        </button> -->
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      oddFormat: "odd_a",
      monto: 0,
      montoPremio: 0,
      toggleList: true
    };
  },
  watch: {
    monto(m) {
      this.$store.commit("setAmount", m);
      this.updatePrize();
      return this.getAmount;
    }
  },
  computed: {
    ...mapGetters([
      "getFormat",
      "getOdds",
      "getBetType",
      "getPrize",
      "getFloatTicket",
      "getAmount",
      "getThemelight",
      "getMarkRestricted",
      "getOddsRestricteds"
    ])
  },
  methods: {
    ...mapActions(["processOdd", "updatePrize", "deleteRestrictednOdd"]),
    isNumber(val) {
      if (val.key != "." && isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    restrictedParley(id) {
      return this.getOddsRestricteds.includes(id);
    },
    cleanData() {
      this.monto = 0;
      this.$store.dispatch("cleanOdds");
    },
    changeFormat(oddFormat) {
      this.$store.commit("setFormat", oddFormat);
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    },
    async deleteOdd(item) {
      if (this.getBetType == "Directa") {
        this.deleteRestrictednOdd(item);
      }
      await this.processOdd(item);
      await this.updatePrize();
    }
  }
};
</script>

<style>
.contenedor-floating {
  background: rgba(49, 55, 61, 0.2);
  opacity: 0.2;
  z-index: 1 !important;
  height: 85%;
}
.contenedor-floating-light {
  background: rgba(244, 244, 244, 0.8);
  opacity: 0.8;
  color: #000;
  z-index: 1 !important;
  height: 85%;
}
</style>
