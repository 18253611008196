<template>
  <div class="container-tickets" :class="{ 'bg-ticket': getThemelight }">
    <div
      class="text-center mt-1"
      :class="{
        'animate__animated animate__fadeOutRight': !getMinTicket,
        'animate__animated animate__fadeInRight': getMinTicket
      }"
    >
      <label
        style="text-align: left"
        :class="{
          'label-title-header': true
        }"
      >
        Selección de Apuestas
      </label>
    </div>
    <div
      class="d-flex btn-group"
      :class="{
        'animate__animated animate__fadeOutRight': !getMinTicket,
        'animate__animated animate__fadeInRight': getMinTicket
      }"
    >
      <button
        :class="{
          'fc-prev-button btn btn-dark activadores-parley-directas': true,
          active: getFormat == 'odds'
        }"
        type="button"
        aria-label="prev"
        @click="changeFormat('odds')"
      >
        <span class="text-option">Americano</span>
      </button>
      <button
        :class="{
          'fc-prev-button btn btn-dark activadores-parley-directas': true,
          active: getFormat == 'odds_a'
        }"
        type="button"
        aria-label="next"
        @click="changeFormat('odds_a')"
      >
        <span class="text-option">Decimal</span>
      </button>
    </div>

    <div
      class="my-2 text-center"
      :class="{
        'animate__animated animate__fadeOutRight': !getMinTicket,
        'animate__animated animate__fadeInRight': getMinTicket
      }"
    >
      <i aria-hidden="true" class="fas fa-inbox mx-2"></i>Cantidad Apuestas
      <span class="badge bg-white ml-2">{{ this.getOdds.length }}</span>
    </div>

    <div
      :class="{
        'animate__animated animate__fadeOutRight': !getMinTicket,
        'animate__animated animate__fadeInRight': getMinTicket
      }"
    >
      <div class="d-flex btn-group mt-1 border-bottom border-top border-light">
        <button
          type="button"
          :class="{
            'fc-prev-button btn btn-dark activadores-formato': true,
            selected: getBetType == 'Parley'
          }"
          @click="$store.dispatch('verifyOdds')"
        >
          <span class="text-option">Parley</span>
        </button>

        <button
          type="button"
          :class="{
            'fc-prev-button btn btn-dark activadores-formato': true,
            selected: getBetType == 'Directa'
          }"
          @click="$store.commit('setBetType', 'Directa')"
        >
          <span class="text-option">Directa</span>
        </button>
      </div>
      <section class="detalles-calculos">
        <div class="cantidad-section">
          <label for="input-cantidad" class="label-monto">
            <b>Monto de la Apuesta</b>
          </label>
          <form>
            <input
              type="text"
              class="input-cantidad"
              style="margin: 0.5em 0"
              v-model="monto"
              @keypress="isNumber"
              :class="{ 'input-cantidad-light': getThemelight }"
            />
          </form>

          <button
            class="btn btn-app bg-danger icon-trash btn-clear"
            v-b-tooltip.hover.left="'Limpiar Todas las Apuestas'"
            @click="cleanData"
            :disabled="getOdds.length == 0"
          >
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="cantidad-premio" v-if="getBetType == 'Parley'">
          <label class="label-title-premio">PREMIO</label>
          <label class="label-cantidad-premio">{{ getPrize }}</label>
        </div>
        <button
          :class="{
            'btn btn-block bg-gradient-primary btn-xs btn-generar mb-1': true
          }"
          :disabled="getOdds.length == 0"
          @click="generarTicket"
        >
          Generar Apuesta
        </button>
        <div style="overflow-y: auto; max-height: 18em">
          <div
            class="callout p-2 text-uppercase"
            :class="{
              'bg-callout text-dark': getThemelight,
              'bg-dark text-light': !getThemelight,
              'callout-danger bg-restringida': restrictedParley(item.id) && getMarkRestricted,
              'callout-success': !restrictedParley(item.id) || !getMarkRestricted
            }"
            v-for="(item, key) in getOdds"
            :key="key"
          >
            <div class="d-flex m-0 py-0 height-call text-center">
              <!-- <div class="m-auto p-0"><h5>VS</h5></div> -->
              <div class="align-self-center flex-wrap p-0 w-50">
                <div class="mb-1" style="font-size: 10px">{{ item.home }}</div>
                <div style="font-size: 10px">{{ item.away }}</div>
              </div>
              <div class="m-auto p-0 w-25">
                <div class="mb-1 p-0" style="font-size: 10px">{{ item.outcome }} ( {{ item.type }} )</div>
                <div class="p-0" style="font-size: 10px">
                  <template v-if="showSign(getFormat, item[getFormat])">+</template>{{ item[getFormat] }}
                  <template v-if="item.factor">({{ item.factor }})</template>
                </div>
              </div>
              <div class="m-auto p-0 w-25">
                <div class="btn btn-trash pb-3" v-b-tooltip.hover.left="'Borrar esta apuesta'" @click="deleteOdd(item)">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div class="premio-individual mt-2" v-if="getBetType == 'Directa'">
              <label class="label-title-premio">PREMIO</label>
              <label class="label-cantidad-premio">{{ item.prize }}</label>
            </div>
          </div>
        </div>

        <button class="btn text-light btn-float" @click="hideTickets" :class="{ 'text-dark': getThemelight }">
          <i class="fas fa-expand-arrows-alt"></i>
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      oddFormat: "odd_a",
      monto: 0,
      montoPremio: 0,
      toggleList: true
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOdds",
      "getFormat",
      "getPrize",
      "getBetType",
      "getMinTicket",
      "getThemelight",
      "getOddsRestricteds",
      "getMarkRestricted",
      "getAmount",
      "getBetInit",
      "getMsjError"
    ])
  },
  watch: {
    monto(m) {
      this.$store.commit("setAmount", m);
      this.updatePrize();
    }
  },
  mounted() {
    this.$store.commit("setAmount", 0);
  },
  methods: {
    ...mapActions(["processOdd", "updatePrize", "deleteRestrictednOdd", "validTicket"]),
    formatoMonto(m) {
      let montoFormato = 0;
      if (m) montoFormato = m;

      return parseFloat(montoFormato).toFixed(2);
    },
    isNumber(val) {
      if (val.key != "." && isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    changeFormat(oddFormat) {
      this.$store.commit("setFormat", oddFormat);
    },
    async deleteOdd(item) {
      if (this.getBetType == "Directa") {
        this.deleteRestrictednOdd(item);
      }

      await this.processOdd(item);
      await this.updatePrize();
    },
    cleanData() {
      this.monto = 0;
      this.$store.dispatch("cleanOdds");
    },
    restrictedParley(id) {
      return this.getOddsRestricteds.includes(id);
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    },
    hideTickets() {
      this.$store.commit("setMinTicket", false);
      this.$store.commit("setFloatTicket", false);
    },

    //Generacon de tickets
    async generarTicket() {
      await this.validTicket();

      if (this.getMsjError) {
        this.Swal.fire("Ha ocurrido un error", this.getMsjError, "error");
      } else {
        let dataTicket = [];
        let objData = {};

        const cantidad_amount_id = this.getBetType == "Parley" ? this.getAmount : this.getAmount * this.getOdds.length;

        this.getOdds.forEach(element => {
          let id = "FTX1X" + element.game_id;

          objData[id] = {
            code: element.game_id + "XFTX1",
            factor: "",
            game_id: element.game_id,
            odd: element.odds,
            odd_a: element.odds_a,
            odd_type: 3,
            status: 1,
            type: element.type
          };
        });

        dataTicket["Ticket"] = {
          amount: this.getAmount,
          amount_ind: cantidad_amount_id,
          modalidad: this.getBetType == "Parley",
          bonus: this.checkBonus,
          code: this.getBetInitData,
          // eslint-disable-next-line no-undef
          procesed: moment().format("YYYY-MM-DD"),
          moneda: this.getUser.Coin.moneda,
          Odds: objData
        };

        const respuesta = await this.$store.dispatch("registerTicketAsync", dataTicket);

        if (respuesta.Type_error && respuesta.Type_error != 0) {
          this.Swal.fire({
            position: "top",
            title: "Error",
            text: respuesta.Error,
            icon: "error"
          });
        }
      }

      //Cambia a tipo directa
      if (this.getOdds.length == 1 && this.getBetType == "Parley") {
        this.$store.commit("setBetType", "Directa");
      }
    }
  }
};
</script>

<style>
.bg-ticket {
  background-color: #d7d7d7 !important;
  color: #4e4f5a;
}
.bg-callout.bg-restringida {
  background: #745f00 !important;
  color: white !important;
}
.bg-callout {
  background: linear-gradient(360deg, #cde1f1, #ffffff);
}
.btn-float {
  position: absolute;
  top: 90%;
  left: 0%;
  border: none;
  cursor: pointer;
}
.btn-float:hover {
  background: linear-gradient(to right, #f09819, #ff512f);
}
.btn-floating {
  position: fixed;
  bottom: 0%;
  right: 0px;
  width: 20%;
  height: 3em;
  line-height: 2.5;
  background-clip: border-box;
  border-radius: 0.25rem;
}
.container-tickets {
  background-color: #41474e;
  display: block;
  align-items: center;
}
.height-call {
  height: 2.5em;
}
.activadores-parley-directas {
  font-size: 10px;
  text-transform: uppercase;
  background-color: #31373de8;
  font-weight: 600;
  padding: 0.5em;
  margin: 0.1em;
}

.activadores-parley-directas:hover {
  background-color: #fff;
  color: #423e3a;
}

.activadores-formato {
  font-size: 10px;
  text-transform: uppercase;
  background-color: #31373de8;
  font-weight: 600;
}
.activadores-formato:hover {
  background-color: #fff;
  color: #423e3a;
}
.activadores-formato.selected:hover {
  color: #fff;
  text-shadow: 1px 1px 4px #000;
}
.cantidad-section {
  margin: 0 5px;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.jugadas {
  display: flex;
  flex-flow: row;
  align-items: center;
  color: #fff;
}

.label-monto {
  text-align: center;
  padding: 0;
  margin: 0;
  align-items: center;
  align-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  font-weight: 300 !important;
  font-size: 12px;
  text-transform: uppercase;
}

.input-cantidad {
  background-color: #212529;
  color: #fff;
  border: none;
  text-align: right;
  padding-right: 12px;
  border-radius: 7px;
  font-weight: 500;
  width: 65px;
  height: 35px;
  font-size: 1.2em;
}

.input-cantidad-light {
  background-color: #fff;
  color: black;
}

.icon-trash {
  background-color: transparent !important;
  color: #999999 !important;
  font-size: 1px;
  font-weight: 100;
  height: 5px;
  border: none;
}

.icon-trash:hover {
  color: #eb6060 !important;
}

.premio-individual {
  background-image: linear-gradient(to right, #0e6503, #6afd00);
  height: 20px;
  font-size: 0.8em;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
}

.cantidad-premio {
  margin-bottom: 0px;
  height: 30px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
  background-image: linear-gradient(to right, #0e6503, #6afd00);
  font-size: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
}

.label-title-premio {
  width: 50%;
  text-align: right;
  font-weight: 300;
  height: 100%;
  margin: 0px;
  font-size: 1.2em;
  font-weight: 400 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white !important;
  font-weight: 800;
}

.label-cantidad-premio {
  margin: 0px;
  width: 50%;
  text-align: center;
  text-shadow: #000 1px 1px 3px;
  font-size: 1.3em;
  line-height: 1.2;
  color: white !important;
  font-weight: 800;
}

.btn-generar {
  padding: 0.5em;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  color: #fff;
  opacity: 1;
  font-size: 13px;
  border-radius: 0px;
  background: #007bff linear-gradient(180deg, #268ffd, #011f62) repeat-x !important;
}
.btn-generar:hover {
  background: #007bff linear-gradient(180deg, #011f62, #268ffd) repeat-x !important;
}

.input-cantidad:active,
.input-cantidad:focus,
.input-cantidad:focus-visible {
  background-color: #424a53 !important;
  color: #fff;
  border: none;
  outline: none;
}

.input-cantidad:focus {
  background-color: white;
}

.detalles-calculos {
  position: relative;
  height: 400px;
}

.btn.btn-app.bg-danger.icon-trash {
  background-color: transparent !important;
}

.selected {
  background: #007bff linear-gradient(180deg, #268ffd, #011f62) repeat-x !important;
}

.active {
  background-color: white !important;
  color: #000 !important;
}

.btn-trash {
  background-color: transparent !important;
  color: #999999 !important;
  /* font-size: 1em;
  font-weight: 100;
  height: 10px;
  border: none; */
}

.btn-trash:hover {
  color: #eb6060 !important;
}
</style>
